import React from 'react';
import { Select } from '@mantine/core';
import { useFormikContext } from 'formik';
import { useAppSelector } from '@/hooks/reduxHooks';

interface CustomSelectProps {
  name: string;
  label: string;
  placeholder: string;
  data: { value: string; label: string }[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({ name, label, placeholder, data }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <div>
      <Select
        label={label}
        placeholder={placeholder}
        data={data}
        value={values[name]}
        onChange={(value) => setFieldValue(name, value)}
        className=''
        styles={{
          label: {
            position: 'absolute',
            marginTop: '-36px',
            color: theme === 'light' ? '#4b677b' : '#b6cbda',
            fontSize: '16px',
            fontWeight: 'initial',

          },
          dropdown: {
            backgroundColor: theme === 'light' ? '#e9eff2' : '#1a5f7a',
            border: '0px',
          },
          option: {
            color: theme === 'light' ? '#3c5262' : '#FFF',
            fontSize: '18px',
          },
          input: {
            backgroundColor: '#b6cbda40',
            border: '0px',
            color: theme === 'light' ? '#4b677b' : '#e2eaf0',
            fontSize: '18px',
            fontFamily: 'var(--font-work)',
            paddingLeft: 8,
            height: 40,
            borderRadius: 8
          }
        }}
      />
    </div>
  );
};

export default CustomSelect;
