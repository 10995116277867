'use client';

import { Box, Loader } from '@mantine/core';
import dynamic from 'next/dynamic';
import React, { useEffect, useCallback, Suspense } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { useInView } from 'react-intersection-observer';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { SelectedPage } from '@/types/types';
import { useMediaQuery } from '@mantine/hooks';
import { CompaniesIndexesByYear } from '@/types/contentTypes';

const RootBox = dynamic(() => import('@/shared/RootBox').then((mod) => mod.RootBox), { ssr: false });
const SectionTitle = dynamic(() => import('@/shared/SectionTitle').then((mod) => mod.SectionTitle), { ssr: false });
const SectionSubtitle = dynamic(() => import('@/shared/SectionSubtitle').then((mod) => mod.SectionSubtitle), { ssr: false });
const IndexHoldersTabs = dynamic(() => import('./IndexHoldersTabs'), { ssr: false });
const IndexHoldersTable = dynamic(() => import('./IndexHoldersTable'), { ssr: false });
const MobileIndexHoldersTabs = dynamic(() => import('./mobile/MobileIndexHoldersTabs'), { ssr: false });
const MobileIndexHoldersTable = dynamic(() => import('./mobile/MobileIndexHoldersTable'), { ssr: false });

type CompaniesSelectionProps = {
  content: CompaniesIndexesByYear;
};

export const CompaniesSelection = ({ content }: CompaniesSelectionProps) => {
  const dispatch = useAppDispatch();
  const selectedQuarter = useAppSelector(
    (state) => state.indexHoldersQuarterTabs.activeQuarter
  );

  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.CompaniesSelection));
    }
  }, [inView, dispatch]);

  const loading = null; // Async loading state placeholder

  const selectedQuarterData = useCallback(() => {
    return content?.years
      .flatMap((year) =>
        year.quarters
          .filter((item) => item.quarter === selectedQuarter)
          .flatMap((quarter) => quarter.items)
      );
  }, [content, selectedQuarter]);

  const Description = () => (
    <p className='xl:text-lg text-secondary-500 dark:text-lightBlue-200 w-[70%] xxs:max-md:w-full'>
      We started preselections and deep research analysis for All-GLS Growth Index from Q3 2023 to continue quarterly. The quarterly holders pick indicated above belong to index top-20, however the 5 companies selection is modified from optimal due to non-disclosure considerations.
    </p>
  );

  return (
    <Box
      id={SelectedPage.CompaniesSelection}
      ref={ref}
      className='w-full h-full '
    >
      <Suspense fallback={<Loader color='white' />}>
        <RootBox>
          <SectionTitle title='Companies Selection' index={6} />
          <SectionSubtitle subtitle="The work started from Q3 2023 is continued quarterly on all supported strategies. ALL GLS VC Strategy holders are represented below with slight modification due to NDA reasons." />

          {loading ? (
            <Box className='flex w-full h-20 justify-center items-center'>
              <Loader color='white' />
            </Box>
          ) : (
            <Box className='flex flex-col'>
              {isOnLargeScreen ? (
                <Box>
                  <Box className='mt-6 mb-12'>
                    <Description />
                  </Box>
                  <IndexHoldersTabs content={content} />
                  <IndexHoldersTable content={selectedQuarterData()} />
                </Box>
              ) : (
                <Box className='mt-12'>
                  <MobileIndexHoldersTabs content={content} />
                  <MobileIndexHoldersTable
                    selectedQuarterData={selectedQuarterData()}
                  />
                  <Box className='mt-12'>
                    <Description />
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </RootBox>
      </Suspense>
    </Box>
  );
};
