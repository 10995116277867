'use client';

import { Box } from '@mantine/core';
import { FormComponent } from './FormComponent';
import { RootBox } from '@/shared/RootBox';
import { SectionTitle } from '@/shared/SectionTitle';
import { useInView } from 'react-intersection-observer';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { SelectedPage } from '@/types/types';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

type Props = {};

export const ContactUs = (props: Props) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const [formState, setformState] = useState<string>('notSubmitted');

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Contacts));
    }
  }, [inView, dispatch]);

  const handleFormSubmit = (status: string) => {
    setformState(status);
  };

  return (
    <Box id='contacts' ref={ref} className='w-full h-full'>
      <RootBox>
        <SectionTitle title='Contacts' index={7} />
        <Box className='flex xxs:max-md:flex-col justify-between w-full h-full mt-12 mb-20 xxs:max-md:mb-0'>
          <Box className='text-secondary-500 dark:text-lightBlue-200 text-lg w-2/6 xxs:max-md:w-full flex flex-col '>
            <p className='text-xl font-bold'>
              In Arcanis we are open for mutually beneficial business
              collaboration and partnerships.
            </p>
            <p className='mt-4'>Our main vectors include:</p>
            <Box className='flex flex-col mt-4'>
              <Box className='flex gap-2 items-start'>
                <Box className='flex w-4 h-4 text-primary-500 translate-y-2'>
                  <ArrowRightCircleIcon />
                </Box>
                <p className=''>products distribution partnerships</p>
              </Box>
              <Box className='flex gap-2 items-start mt-4'>
                <Box className='flex w-4 h-4 text-primary-500 translate-y-2'>
                  <ArrowRightCircleIcon />
                </Box>
                <p className=''>
                  collaboration in secondary sourcing and brokerage
                </p>
              </Box>
              <Box className='flex gap-2 items-start mt-4'>
                <Box className='flex w-4 h-4 text-primary-500 translate-y-2'>
                  <ArrowRightCircleIcon />
                </Box>
                <p className=''>
                  customizing new products using our technology for large
                  clients
                </p>
              </Box>
            </Box>
          </Box>
          <Box className='w-4/6 ml-20 xxs:max-md:w-full xxs:max-md:ml-0 xxs:max-md:mt-12'>
            {formState === 'notSubmitted' && <FormComponent onFormSubmit={handleFormSubmit} />}
            {formState === 'submitted' && (
              <Box className='text-lg flex flex-col gap-2'>
                <p className='text-lightBlue-200 text-xl font-bold'>Thank you!</p>
                <p className='text-lightBlue-500 text-lg'>Your message has been sent. We will get back to you shortly.</p>
              </Box>
            )}
            {formState === 'error' && (
              <Box className='text-lg flex flex-col gap-2'>
                <p className='text-lightBlue-500 text-xl font-bold'>Error!</p>
                <p className='text-lightBlue-500 text-lg'>There was an error sending your message. Please try again later.</p>
              </Box>
            )}
          </Box>
        </Box>
      </RootBox>
    </Box>
  );
};
