'use client';

import React, { useEffect, useMemo } from 'react';
import { Box } from '@mantine/core';
import { SectionTitle } from '@/shared/SectionTitle';
import { useParallax } from 'react-scroll-parallax';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { SectionSubtitle } from '@/shared/SectionSubtitle';
import { SelectedPage } from '@/types/types';
import { ProductFeaturesCardsContent } from '@/types/contentTypes';
import { useInView } from 'react-intersection-observer';
import { RootBox } from '@/shared/RootBox';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mantine/hooks';

const ElementWarp = dynamic(
  () => import('@/../public/abstractShapes/elementWarp.svg'),
  { ssr: false }
);

const FoldingCards = dynamic(() => import('./FoldingCards'), { ssr: false });

type Props = {
  content: ProductFeaturesCardsContent;
};

export const Products: React.FC<Props> = ({ content }: Props) => {
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');

  const dispatch = useAppDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const { ref: bgMeshRef } = useParallax<HTMLDivElement>({
    speed: 15,
    rotate: [180, 185],
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Advantages));
    }
  }, [inView, dispatch]);

  const memoizedContent = useMemo(() => content, [content]);

  return (
    <Box
      className='relative w-full z-0 '
      id='advantages'
      ref={ref}
      style={{ overflow: 'hidden' }}
    >
      {isOnLargeScreen ? (
        <div
          ref={bgMeshRef}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            overflow: 'visible',
          }}
        >
          <Box className='opacity-15 w-1/3 h-full'>
            <ElementWarp />
          </Box>
        </div>
      ) : (
        <div ref={bgMeshRef} style={{position: 'absolute'}}></div>
      )}
      <RootBox>
        <SectionTitle title='Advantages' index={2} />
        <SectionSubtitle
          subtitle='Arcanis develops systematic and continuous VC strategies grounded in our Growth Index selection, verified by Deep Research. This approach enables a new class of index-following VC strategies, providing crucial advantages for both investors and target companies. Experience enhanced insights and opportunities with our tailored methodologies.'
          highlights={[
            'systematic and continuous VC strategies',
            'sequence of vintages',
            'Growth Index',
            'VC strategies',
            'Deep Research',
          ]}
        />
        {/* <SectionSubtitle
          mt={6}
          subtitle='This approach opens a whole class of index-following VC strategies designed to deliver crutial advantages to investors and target companies:'
          highlights={[
            'class of index-following VC strategies',
            'deliver crutial advantages',
          ]}
        /> */}
        <Box className='flex flex-col mt-12 '>
          <Box className='flex w-full h-full mb-20 md:mb-40'>
            <FoldingCards content={memoizedContent} />
          </Box>
        </Box>
      </RootBox>
    </Box>
  );
};

Products.displayName = 'Products';
