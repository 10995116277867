'use client';

import { Box, SimpleGrid, Stack, Divider } from '@mantine/core';
import { RootBox } from '@/shared/RootBox';
import { SectionTitle } from '@/shared/SectionTitle';
import { SectionSubtitle } from '@/shared/SectionSubtitle';
import React, { useEffect, useMemo } from 'react';
import {
  SecuritizationContent,
  SecuritizationFeatureItem,
  SecuritizationItemType,
} from '@/types/contentTypes';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { SelectedPage } from '@/types/types';
import { useInView } from 'react-intersection-observer';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

type SecuritizationProps = {
  content: SecuritizationContent;
};

const SecuritizationItem: React.FC<SecuritizationItemType> = React.memo(
  ({ title, description, index }) => (
    <Stack className='items-center' key={index}>
      <Box className='flex xxs:max-md:flex-col w-full text-secondary-500 dark:text-lightBlue-200 items-start'>
        <Box className='font-montserrat md:w-2/6 font-semibold text-sm xxs:max-md:text-xs text-secondary-400 dark:text-lightBlue-400/80 tracking-wider md:pr-4 translate-y-1'>
          {title}
        </Box>
        <Box className='flex justify-start items-start md:items-center md:w-4/6'>
          <Box className='font-bold text-xl xxs:max-md:text-lg md:ml-8 md:pr-8 xxs:max-md:mt-2'>
            {description}
          </Box>
        </Box>
      </Box>
      {index && index < 6 ? (
        <Divider my={12} color='#FFFFFF30' className='md:hidden' />
      ) : null}
    </Stack>
  )
);

SecuritizationItem.displayName = 'SecuritizationItem';

const SecuritizationFeature: React.FC<SecuritizationFeatureItem> = ({
  number,
  title,
  description,
}) => (
  <Box className='flex justify-center items-start w-full mt-4'>
    <Box className='flex w-5 h-5 text-primary-500 items-center justify-center translate-y-0.5'>
      <ArrowRightCircleIcon />
    </Box>
    <p className='xl:text-lg text-secondary-500 dark:text-lightBlue-200 font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
      <span className='font-semibold'>{title}</span> {description}
    </p>
  </Box>
);

SecuritizationFeature.displayName = 'SecuritizationFeature';

export const Securitization: React.FC<SecuritizationProps> = ({ content }) => {
  const dispatch = useAppDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.DeepResearch));
    }
  }, [inView, dispatch]);

  const features = content.features;
  const securitization = content.securitization;

  return (
    <Box id='deepresearch' ref={ref} className='w-full h-full relative'>
      <RootBox>

        <SectionTitle title='Deep Research' index={3} />
        <SectionSubtitle subtitle='Arcanis Deep Research Methodology is powered by a complete and up-to-date dataset of 31,000 GLS companies. It guides company selection with a focus on transparency and scalability, prioritizing an optimal return-risk balance. This ensures informed decisions that align with your investment goals.' />
        <Box className='flex flex-col w-[70%] xxs:max-md:w-full gap-4 mt-6'>
          {/* {features.map(({ number, title, description }, index) => (
            <SecuritizationFeature
              number={number}
              title={title}
              description={description}
              key={index}
            />
          ))} */}
        </Box>
        <Box className='flex flex-col gap-8 w-2/3 text-xl '>
          <Box
            className='flex justify-center items-start w-full'>
            <Box className='flex w-6 h-6 text-primary-500 items-center justify-center translate-y-1'>
              <ArrowRightCircleIcon />
            </Box>
            <p className=' text-secondary-500 dark:text-lightBlue-200 font-base xxs:max-md:font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
            <span className='font-semibold'> Volatility Estimation: </span> Assess the achievable and minimum investment cash-on-cash range for the next 12 quarters.
            </p>
          </Box>

          <Box
            className='flex justify-center items-start w-full'>
            <Box className='flex w-6 h-6 text-primary-500 items-center justify-center translate-y-1'>
              <ArrowRightCircleIcon />
            </Box>
            <p className=' text-secondary-500 dark:text-lightBlue-200 font-base xxs:max-md:font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
            <span className='font-semibold'>Open Risks Identification: </span> Identify and evaluate open investment risks, gauging their potential impact on returns.
            </p>
          </Box>
          <Box
            className='flex justify-center items-start w-full'>
            <Box className='flex w-6 h-6 text-primary-500 items-center justify-center translate-y-1'>
              <ArrowRightCircleIcon />
            </Box>
            <p className=' text-secondary-500 dark:text-lightBlue-200 font-base xxs:max-md:font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
            <span className='font-semibold'> Closed Risks Justification: </span> Provide rationale for closed risks, explaining how they are mitigated and their lack of impact on returns.
            </p>
          </Box>
          <Box
            className='flex justify-center items-start w-full'>
            <Box className='flex w-6 h-6 text-primary-500 items-center justify-center translate-y-1'>
              <ArrowRightCircleIcon />
            </Box>
            <p className=' text-secondary-500 dark:text-lightBlue-200 font-base xxs:max-md:font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
            <span className='font-semibold'> Entry Analysis: </span>Compare accessible companies within the top 20 index selection based on their projected returns, considering the impact of open risks and targeting net IRR.
            </p>
          </Box>
          <Box
            className='flex justify-center items-start w-full'>
            <Box className='flex w-6 h-6 text-primary-500 items-center justify-center translate-y-1'>
              <ArrowRightCircleIcon />
            </Box>
            <p className=' text-secondary-500 dark:text-lightBlue-200 font-base xxs:max-md:font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
              <span className='font-semibold'>Exit Monitoring:</span> Monitor changes quarterly to identify the optimal timing for maximizing IRR on exits.
            </p>
          </Box>
        </Box>
        {/* <Box className='flex w-full mt-20 rounded-xl bg-lightBlue-400/20 dark:bg-lightBlue-500/5 border-lightBlue-500/30 border-[1px] p-8 gap-8'> */}
        {/*  <Box className='transparent-container flex w-full mt-20 rounded-xl p-8 gap-8'>
          <SimpleGrid
            cols={{ base: 1, lg: 2 }}
            spacing={{ base: 20, sm: 'xl' }}
            verticalSpacing={{ base: 'md', sm: 'xl' }}
          >
            {securitization.map(({ title, description }, index) => (
              <SecuritizationItem
                title={title}
                description={description}
                index={index}
                key={index}
              />
            ))}
          </SimpleGrid>
        </Box> */}
      </RootBox>
    </Box>
  );
};

Securitization.displayName = 'Securitization';

export default React.memo(Securitization);
